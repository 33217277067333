import 'slick-carousel/slick/slick.min.js'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme'
import "../stylesheets/home"

$(document).ready(function() {
  var modalAchievement = document.getElementById('modalAchievement')
  modalAchievement.addEventListener('show.bs.modal', function (e) {
    var link = $(e.relatedTarget);
    $(this).find('.modal-body').load(link.data('url'));
  });

 $('.item-achie').click(function(event){
    event.preventDefault();

    $('.item-achie').removeClass('active')
    $(this).addClass('active');

    $('.achievements__list').addClass('d-none')
    $(`.${$(this).data().group}`).removeClass('d-none')

    $('.achievements__list').slick('refresh')
  });

  $('.customer-say__content').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  });

  $('.partner__say').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  });

  $('.achievements__list').slick({
    centerMode: true,
    centerPadding: '60px',
    autoplay: true,
    autoplaySpeed: 2750,
    slidesToShow: 1,
    infinite: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });
});
